<template>
    <div :data="listData" class="warp">
        <div v-for="(item, index) in listData" :key="index" class="once">
            <div style="display: flex;align-items: center;width: 330px;">
                <img class="flag" :src="item.falg" />
                <div class="name">{{ item.name }}</div>
            </div>
            <el-input class="buy" v-model="item.buy" @change="change(item)"></el-input>
            <div class="line"></div>
            <el-input class="buy1" v-model="item.sell" @change="change"></el-input>
        </div>
    </div>
</template>

<script>
import COS from 'cos-js-sdk-v5'
export default {
    data() {
        return {
            listData: [
                { falg: require("../assets/flag/1.jpg"), name: 'USD', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/2.jpg"), name: 'GBP', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/3.jpg"), name: 'AUD', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/4.jpg"), name: 'EUR', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/5.jpg"), name: 'JPY', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/6.jpg"), name: 'CAD', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/7.jpg"), name: 'NZD', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/8.jpg"), name: 'SGD', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/9.jpg"), name: 'MYR', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/10.jpg"), name: 'CHF', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/11.jpg"), name: 'PHP', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/12.jpg"), name: 'KRW', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/13.jpg"), name: 'THB', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/14.jpg"), name: 'MOP', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/15.jpg"), name: 'TWD', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/16.jpg"), name: 'IDR', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/17.jpg"), name: 'SEK', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/18.jpg"), name: 'NOK', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/19.jpg"), name: 'DKK', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/20.jpg"), name: 'BND', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/21.jpg"), name: 'SAR', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/22.jpg"), name: 'AED', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/23.jpg"), name: 'VND', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/24.jpg"), name: 'RUB', buy: 7.815, sell: 7.845, controls: false },
                { falg: require("../assets/flag/25.jpg"), name: 'INR', buy: 7.815, sell: 7.845, controls: false },],
        }
    },
    methods: {
        change() {
            let cos = new COS({
                SecretId: 'AKIDx2HUPQVVYXPQPQTOZfnqntmjYJ0rvsgX',
                SecretKey: '61oVGA0iVJINTdgaJ4kJ0lXID2ujaM4h',
            });
            cos.uploadFile({
                Bucket: '123-1313485283', /* 填写自己的 bucket，必须字段 */
                Region: 'ap-hongkong',     /* 存储桶所在地域，必须字段 */
                Key: '1.txt',              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
                Body: JSON.stringify(this.listData), // 上传文件对象
            }, function (err, data) {
                if (err) {
                    console.log('上传失败', err);
                    alert("修改失败！")
                } else {
                    console.log('上传成功', data);
                    alert("修改成功！")
                }
            });
        },
    },
    beforeCreate() {
        let cos = new COS({
            SecretId: 'AKIDx2HUPQVVYXPQPQTOZfnqntmjYJ0rvsgX',
            SecretKey: '61oVGA0iVJINTdgaJ4kJ0lXID2ujaM4h',
        });
        cos.getObject({
            Bucket: '123-1313485283', /* 填写自己的 bucket，必须字段 */
            Region: 'ap-hongkong',     /* 存储桶所在地域，必须字段 */
            Key: '1.txt',          /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
        }, (err, data) => {
            if (err) return console.log(err);
            this.listData = JSON.parse(data.Body)
        });
    }
}
</script>

<style scoped>
.warp {
    height: 1445px;
    width: 1080px;
    overflow: hidden;
}

.flag {
    height: 46px;
    width: 100px;
    margin: 5px;
    object-fit:cover;
}

.once {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #9cd8f6;
    background: linear-gradient(to right, #d7ab695f 0%, #d7ab695f 32%, #9cd8f6 32%, #9cd8f6 100%);
    overflow: hidden;
    border: 1px solid rgba(27, 26, 26, 0.229)
}

.once:nth-child(2n) {
    background-color: #5bc1d0;
    background: linear-gradient(to right, #d7ab695f 0%, #d7ab695f 32%, #5bc1d0 32%, #5bc1d0 100%);

}

.name {
    font-size: 40px;
    font-weight: bold;
    width: 75px;
    margin-left: 60px;
}

.buy {
    width: 200px;
    font-size: 40px;
}

.buy1 {
    width: 200px;
    font-size: 40px;
}
.line{
    border-right: 1px solid rgba(0, 0, 0, 0.396); 
    height: 56px;
    margin-left: -90px;
}

.buy.el-input>>>.el-input__inner {
    background-color: transparent;
    border: none;
    color: blue;
}
.buy1.el-input>>>.el-input__inner {
    background-color: transparent;
    border: none;
    color: red;
}
</style>